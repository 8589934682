import React from "react";

const DescargaJson = () => {
  // Rutas relativas a los archivos
  const jsonFilePath = "/json_motor_busqueda/charts_data.json";
  const zipFilePath = "/imagenes/images_charts.zip";

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Descarga de Archivos</h1>
      <p>Haz clic en los botones para descargar los archivos disponibles.</p>

      {/* Botón para descargar el archivo JSON */}
      <a
        href={jsonFilePath}
        download="charts_data.json"
        style={{
          display: "inline-block",
          margin: "10px",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          textDecoration: "none",
          borderRadius: "5px",
        }}
      >
        Descargar JSON
      </a>

      {/* Botón para descargar el archivo ZIP */}
      <a
        href={zipFilePath}
        download="images_charts.zip"
        style={{
          display: "inline-block",
          margin: "10px",
          padding: "10px 20px",
          backgroundColor: "#28a745",
          color: "#fff",
          textDecoration: "none",
          borderRadius: "5px",
        }}
      >
        Descargar ZIP
      </a>
    </div>
  );
};

export default DescargaJson;


