import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Row, Table, Nav, ProgressBar ,Carousel, Image} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Bar, Doughnut } from 'react-chartjs-2';
import { Link ,useNavigate  } from "react-router-dom";
import img2 from "../assets/img/img2.jpg";
import img3 from "../assets/img/img3.jpg";
import img4 from "../assets/img/img4.jpg";
import axios from 'axios'
import ChartCard from "../components/charts/ChartCard";
import imgSearch from "./images/buscador.jpg";
import imgLogoInESGenero from "../assets/img/Observatorio_Prometea.jpg";
import WSE from "../assets/img/WSE.jpg";
import Indicadores from "../assets/img/Indicadores.jpg";
import uiuxI from "../assets/img/DisenoAdaptativo.jpg";
import pdf from "../assets/img/pdf.jpg";

import "./general.css";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import Main from '../layouts/Main';
import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';
import ImageCarouselZoom from "../components/carusel/ImageCarouselZoom";
import CardGroup from 'react-bootstrap/CardGroup';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


export default function Home(props) {

  const [charts, setCharts] = useState([]);
  const [chartCount, setChartCount] = useState(0);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}charts/random-chart`)
        .then(response => {
            setCharts(response.data); // Asumiendo que la API devuelve un array de gráficos
            console.log("GRAFICOS ALEATORIOS", response.data);
        })
        .catch(error => console.error('Error fetching charts:', error));
}, []);

useEffect(() => {
  axios.get(`${process.env.REACT_APP_API_URL}charts/count`)
      .then(response => {
          setChartCount(response.data.count); // Almacenar el conteo de gráficos en el estado
          console.log("Conteo de gráficos:", response.data.count);
      })
      .catch(error => console.error('Error fetching chart count:', error));
}, []);


  const d1 = [[0, 38], [1, 32], [2, 31], [3, 33], [4, 34], [5, 35], [6, 38], [7, 37], [8, 39], [9, 34], [10, 33], [11, 32], [12, 34], [13, 38], [14, 42], [15, 43], [16, 45], [17, 43], [18, 45], [19, 48], [20, 45], [21, 46], [22, 44], [23, 42], [24, 46], [25, 48], [26, 55], [27, 54], [28, 58], [29, 69]];
  const d2 = [[0, 12], [1, 11], [2, 9], [3, 10], [4, 15], [5, 9], [6, 11], [7, 12], [8, 7], [9, 16], [10, 18], [11, 24], [12, 16], [13, 10], [14, 9], [15, 16], [16, 15], [17, 28], [18, 20], [19, 15], [20, 20], [21, 12], [22, 15], [23, 12], [24, 10], [25, 12], [26, 14], [27, 18], [28, 20], [29, 21],];

  // Tickets Earnings
  const seriesOne = [{
    name: 'series1',
    data: d1
  }, {
    name: 'series2',
    data: d2
  }];

  const optionOne2 = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#ff00ff', '#ff73ff'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    yaxis: {
      min: 0,
      max: 150,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    tooltip: { enabled: false }
  };

  const seriesFive = [{
    type: 'column',
    data: [[0, 0], [1, 40], [2, 55], [3, 40], [4, 30], [5, 20], [6, 34], [7, 45], [8, 35], [9, 20], [10, 40], [11, 20], [12, 35], [13, 30], [14, 35], [15, 50], [16, 30], [17, 25], [18, 15], [19, 20], [20, 32], [21, 40], [22, 55], [23, 40], [24, 30], [25, 20], [26, 34], [27, 45], [28, 35], [29, 20], [30, 40], [31, 20], [32, 35], [33, 30], [34, 35], [35, 50], [36, 30], [37, 25], [38, 15], [39, 20], [40, 32]]
  }, {
    type: 'area',
    data: [[0, 82], [1, 80], [2, 85], [3, 80], [4, 76], [5, 70], [6, 74], [7, 75], [8, 75], [9, 70], [10, 71], [11, 72], [12, 75], [13, 80], [14, 85], [15, 80], [16, 70], [17, 95], [18, 95], [19, 90], [20, 92], [21, 90], [22, 95], [23, 90], [24, 90], [25, 90], [26, 84], [27, 85], [28, 85], [29, 80], [30, 70], [31, 70], [32, 75], [33, 70], [34, 75], [35, 80], [36, 75], [37, 85], [38, 78], [39, 70], [40, 82]]
  }];


  const optionBar = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 80,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 14
          }
        }
      }
    }
  };
  
  
  // Changing necessary options between dark and light skin
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const {origenIn,setOrigenIn} = props;

  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);


  const navigateDashProyectos = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/proyectos');
  };

  const navigateDashPI = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/intelectual');
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    setMostrarWSE(false);  // Mostrar los resultados de búsqueda
    console.log("ESTADO DEL WSE DESDE EL SIDEBAR", mostrarWSE);
    navigate('/home');
  };

  const navigateEjemplo = () => {
    // 👇️ navigate to /
    navigate('/ejemplo');
  };

  const navigateInesGenero = () => {
    // 👇️ navigate to /

   // window.location.replace('https://www.uautonoma.cl/inesgenero/');
    window.open('https://www.uautonoma.cl/inesgenero/');
  };

  useEffect(() => {
  setMostrarWSE(false)
  setMostrarBD(false)
  setMostrarPI(false)
  setMostrarHome(true)
  setMostrarProyectos(false)
  setMostrarAyuda(false)
  }, [origenIn]);

  if (skin === 'dark') {
    optionBar.scales['x'].grid.color = '#222b41';
    optionBar.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
    optionBar.scales['x'].grid.borderColor = '#222b41';
    optionBar.scales['y'].grid.color = '#222b41';
    optionBar.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
  } else {
    optionBar.scales['x'].grid.color = '#edeff6';
    optionBar.scales['x'].ticks.color = '#42484e';
    optionBar.scales['x'].grid.borderColor = '#edeff6';
    optionBar.scales['y'].grid.color = '#edeff6';
    optionBar.scales['y'].ticks.color = '#42484e';
  }

  const switchSkin = (skin) => {
    const textPrimary = document.getElementsByClassName('text-primary-dark');

    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.add('text-primary');
      }

    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.remove('text-primary');
      }
    }
  }

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const navigate = useNavigate();
  const ClickBotonWSE = (e) => {
//<Main origen="App"/>
alert("En ClickBotonWSE"+e)

}

  const imagesData = [imgLogoInESGenero,Indicadores,WSE,uiuxI,pdf];
  const arr = Array.from(imagesData);

  useEffect(() => {

  }, [arr]);

  useEffect(() => {

    const handleNextSlide = () => {
      const nextButton = document.querySelector('#videoCarousel .carousel-control-next');
      if (nextButton) {
        nextButton.click();
      }
    };

    const timer = setTimeout(handleNextSlide, 3000);

    return () => clearTimeout(timer);
  }, []);
  
  return (

    
    <React.Fragment>
      <div className="main main-app  p-3 p-lg-4 " style={{ minHeight: "0" }}>
        <div>
        <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Inicio</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Home</li>
            </ol>   
        </div>
        <div className=" main-card">
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {charts.map((chart, index) => (
            <ChartCard key={index} chart={chart} />
          ))}
        </div>
        </div>
              
            </div>
          

      

      <div className="main p-3 p-lg-4 " style={{ marginTop: "-20px" }} >


      <Row className="g-3 ">
      <Col md="7" xs="12" style={{ height: '445px' }}>
      <Card className="card-one" >
        <Card.Header>
          <Card.Title as="h6">Canal de youtube InES Ciencia</Card.Title>
        </Card.Header>
        <Card.Body >   
        <div className="d-flex justify-content-left">
      <div id="videoCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <iframe id="video0"  className="video-iframe" src="https://www.youtube.com/embed/OmwQyNN5bl0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className="carousel-item">
            <iframe id="video1"  className="video-iframe" src="https://www.youtube.com/embed/xUy4L9hLIQs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className="carousel-item">
            <iframe id="video2"   className="video-iframe" src="https://www.youtube.com/embed/QQHzeYEByvo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#videoCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#videoCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
        </Card.Body>
      </Card>
      </Col>

      <Col md="5" xl="5">
  <Card className="card-one">
    <Card.Header>
      <Card.Title as="h6">Links de ínteres</Card.Title>
      <Nav className="nav-icon nav-icon-sm ms-auto">
        <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
      </Nav>
    </Card.Header>
    <Card.Body className="p-3">
       <ul className="events-reviews">
        {[
          {
            "avatar": {
              "bg": "primary",
              "initial": "U"
            },
            "name": "Página de InES Género de la Universidad Autónoma de Chile",
            "star": ["fill", "fill", "fill", "fill", "half-fill"],
            "date": "2 hours ago",
            "text": "Enlace web de InES Género en sitio web institucional",
            "link": "https://www.uautonoma.cl/inesgenero/"
          },
        ].map((item, index) => (
          <li key={index}>
            <div className="d-flex align-items-center mb-2">
              <div className="avatar">
                <span className={"avatar-initial bg-" + item.avatar.bg}>{item.avatar.initial}</span>
              </div>
              <div className="review-body">
                <h6 className="text-dark">{item.name}</h6>
              </div>
            </div>
            <p><Link to={item.link} target="_blank" >{item.text}</Link></p>
          </li>
        ))}
      </ul>
    </Card.Body>
  </Card>
</Col>
      </Row>

        <CardGroup>     
        <Col xl="12">

         <Col xl="12">
<Row className="g-3 pt-3">
  <Col xs={12} md={4}>
    <Card className="card-one">
      <Card.Body className="p-3">
        <div className="d-block fs-40 lh-1 text-primary mb-1">
          <i className="ri-calendar-todo-line icon-red"></i>
        </div>
        <h1 className="card-value mb-0 ls--1 fs-32">{chartCount}</h1>
        <label className="d-block mb-1 fw-medium text-dark">Gráficos actuales en motor de búsqueda</label>
      </Card.Body>
    </Card>
  </Col>
  <Col xs={12} md={4}>
    <Card className="card-one">
      <Card.Body className="p-3">
        <div className="d-block fs-40 lh-1 text-ui-02 mb-1">
          <i className="ri-calendar-check-line icon-red"></i>
        </div>
        <h1 className="card-value mb-0 fs-32 ls--1">2</h1>
        <label className="d-block mb-1 fw-medium text-dark">Dashboard de Indicadores de género</label>
      </Card.Body>
    </Card>
  </Col>
  <Col xs={12} md={4}>
    <Card className="card-one">
      <span>
        <Card.Body className="p-3 pt-3">
          <div className="d-flex flex-column align-items-center">
            <h1 className="card-value fs-16 mb-2 ls--1">Paneles de visualización de indicadores</h1>
            <p className="mb-3 button-container">
              <Button variant="primary" className="custom-button" onClick={() => navigateDashProyectos()}>Proyectos</Button>
              <span className="custom-button-spacer"></span>
              <Button as="a" variant="primary" className="custom-button" onClick={() => navigateDashPI()}>P. Intelectual</Button>
            </p>
          </div>
        </Card.Body>
      </span>
    </Card>
  </Col>
</Row>

             </Col>
<Row className="g-3 pt-3">  
<Col md="6" xs="12" style={{ height: '300px' }}>
      <Card className="card-one" >
        <Card.Header>
          <Card.Title as="h6">Imagenes InES Ciencia Abierta</Card.Title>
        </Card.Header>
        <Card.Body >   
        <ImageCarouselZoom data={arr}/>
      <div className="d-flex flex-column align-items-center">
      <h1 className="card-value fs-16 mb-1 ">Presiona la imagen para agrandarla</h1>  
      </div>
        </Card.Body>
      </Card>
      </Col>             
      <Col md="6" xs="12" style={{ height: '300px' }}>
      <Card className="card-one" >
        <Card.Header>
          <Card.Title as="h6">Imagenes InES Ciencia Abierta</Card.Title>
        </Card.Header>
        <Card.Body >   
        <ImageCarouselZoom data={arr}/>
      <div className="d-flex flex-column align-items-center">
      <h1 className="card-value fs-16 mb-1 ">Presiona la imagen para agrandarla</h1>  
      </div>
        </Card.Body>
      </Card>
      </Col>  
</Row>
</Col> 

</CardGroup>
        <Footer />
      </div>
    </React.Fragment>
  )
}