import React from "react";
//import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './layouts/Main';
//import Header from './layouts/Header';
import NotFound from "./pages/NotFound";
//import {ImageBackground, StyleSheet, Text, View} from 'react-native';
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import UserManager from "./functions/SessionManager";
import {MyEstadoGlobalContext} from './layouts/MyEstadoGlobalContext';

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
//const image = {uri: 'https://reactjs.org/logo-og.png'};



// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  UserManager.Start();
  const [mostrarBD, setMostrarBD] = React.useState(false);
  const [mostrarWSE, setMostrarWSE] = React.useState(true);
  const [mostrarPI, setMostrarPI] = React.useState(true);
  const [mostrarProyectos, setMostrarProyectos] = React.useState(true);
  const [mostrarHome, setMostrarHome] = React.useState(true);
  const [mostrarAyuda, setMostrarAyuda] = React.useState(true);

  return (
    <MyEstadoGlobalContext.Provider value={{mostrarBD, setMostrarBD,mostrarWSE, setMostrarWSE,mostrarPI, setMostrarPI, mostrarProyectos, setMostrarProyectos, mostrarHome, setMostrarHome, mostrarAyuda, setMostrarAyuda}}>
    {/* <React.Fragment>
      <BrowserRouter>
        <Routes> */}
        <Router>
          <Routes>
            <Route path="/" element={<Main origen="App"/>} > 
             {protectedRoutes.map((route, index) => {
              return (
                 
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
           </Route>  
       
        
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}   

          <Route path="*" element={<NotFound />} /> 
          <Route path="/nofound" element={<NotFound />} />
           {/*<Route path="/nofound" component={NotFound} />*/}
        {/* </Routes>
      </BrowserRouter>
    </React.Fragment> */}
    </Routes>
    </Router>
    </MyEstadoGlobalContext.Provider>
  );
}
// export default function App() {
//   UserManager.Start();
//   const [mostrarBD, setMostrarBD] = React.useState(false);
//   const [mostrarWSE, setMostrarWSE] = React.useState(true);
//   const [mostrarPI, setMostrarPI] = React.useState(true);
//   const [mostrarProyectos, setMostrarProyectos] = React.useState(true);
//   const [mostrarHome, setMostrarHome] = React.useState(true);
//   const [mostrarAyuda, setMostrarAyuda] = React.useState(true);

//   return (
//     <MyEstadoGlobalContext.Provider value={{mostrarBD, setMostrarBD,mostrarWSE, setMostrarWSE,mostrarPI, setMostrarPI, mostrarProyectos, setMostrarProyectos, mostrarHome, setMostrarHome, mostrarAyuda, setMostrarAyuda}}>
//     <React.Fragment>
//       <BrowserRouter>
//         <Routes>
//            <Route path="/" element={<Main origen="App"/>} > 
//             {protectedRoutes.map((route, index) => {
//               return (
                 
//                 <Route
//                   path={route.path}
//                   element={route.element}
//                   key={index}
//                 />
//               )
//             })}
//            </Route> 
       
        
//           {publicRoutes.map((route, index) => {
//             return (
//               <Route
//                 path={route.path}
//                 element={route.element}
//                 key={index}
//               />
//             )
//           })}   

//           {/* <Route path="*" element={<NotFound />} /> 
//           <Route exact path="/nofound" element={<NotFound />} />*/}
//           <Route path="/nofound" component={NotFound} />
//         </Routes>
//       </BrowserRouter>
//     </React.Fragment>
//     </MyEstadoGlobalContext.Provider>
//   );
// }