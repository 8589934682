import React from "react";
import Forbidden from "../pages/Forbidden";
import ForgotPassword from "../pages/ForgotPassword";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import Signin2 from "../pages/Signin2";
import Signup from "../pages/Signup";
import Signup2 from "../pages/Signup2";
import VerifyAccount from "../pages/VerifyAccount";
import DescargaJson from "../pages/DescargaJson"; // Importar tu nuevo componente
import { CentralSE } from "../layouts/CentralSE";
import Home from "../home/Home";
import Ayuda from "../home/Ayuda";
import Ejemplo from "../home/Ejemplo";
import PropiedadIntelectual from "../dashboard/PropiedadIntelectual";
import Proyectos from "../dashboard/Proyectos";
import Ingreso from "../pages/Ingresar";
import DatabaseMenu from "../dashboard/DatabaseMenu";
import Header from "../layouts/Header";
import Main from "../layouts/Main";

const publicRoutes = [
  // { path: "pages/signin", element: <Signin /> },
  // { path: "pages/signin2", element: <Signin2 /> },
  // { path: "pages/signup", element: <Signup /> },
  // { path: "pages/signup2", element: <Signup2 /> },
  // { path: "pages/verify", element: <VerifyAccount /> },
  // { path: "pages/forgot", element: <ForgotPassword /> },
  { path: 'pages/lock', element: <LockScreen /> },
  { path: 'pages/error-404', element: <NotFound /> },
  { path: 'pages/error-500', element: <InternalServerError /> },
  { path: 'pages/error-503', element: <ServiceUnavailable /> },
  { path: 'pages/error-505', element: <Forbidden /> },
  { path: 'descarga-json', element: <DescargaJson /> },
  //{ path: 'mostrar/:chartId', element: <CentralSE origenIn='CentralSE'/>},
  { path: 'mostrar/:chartId', element: <Header origenIn='Header'/>},

  // { path: "dashboard/intelectual", element: <PropiedadIntelectual origenIn='PI'/> },
  // { path: "dashboard/proyectos", element: <Proyectos origenIn='Proyectos'/> },
  // { path: "home", element: <Home origenIn='Home'/> },
  // { path: "home/ayuda", element: <Ayuda origenIn='Help'/> },
  // { path: "ejemplo", element: <Ejemplo origenIn='Ejemplo'/>},
  // { path: "pages/ingreso", element: <Ingreso /> },
  // { path: "dashboard/databasemenu", element: <DatabaseMenu origenIn='Database'/> },
  // { path: "header", element: <Header /> },
  // { path: "/mostrar/:chartId", element: <Main/>},
];

export default publicRoutes;
